<template>
  <div class="topWrap">
    <div class="topAreWrap">
      <div class="headerWrap">
        <div class="header">
          <div class="left">
            
            <span class="link" @click="routerGo('home')">
              <img src="../assets/HomePage/logo@2x.png" alt="" />
            </span>
          </div>
          <div class="right">
            <span class="link" @click="newOpen('https://slager.link')">
              Slager</span
            >
            <span class="link" @click="routerGo('AboutUs')">联系我们</span>
            <!-- <span>中/英</span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    newOpen(url) {
      window.open(url);
    },

    routerGo(routeName) {
      const { href } = this.$router.resolve({
        name: routeName,
      });
      window.open(href,'_self');
    },
  },
};
</script>

<style lang="scss" scoped>
.topWrap {
  width: 100%;
  height: 70px;
  background-image: url("../assets/HomePage/bg@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 70px;

  .topAreWrap {
   width: 100%;
   display: flex;
   justify-content: center;
   position: fixed;
    .headerWrap {
      width: 1200px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      margin: 0 auto;

      .header {
        width: 1200px;
        display: flex;
        justify-content: space-between;

        .left {
          cursor: pointer;
          img {
            width: 154px;
            height: 48px;
          }
        }

        .right {
          width: 266px;
          span {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 52px;
            cursor: pointer;
            &:hover {
              color: #2254f6;
            }
          }
          span:nth-of-type(2),
          span:nth-of-type(3) {
            line-height: 52px;
            padding-left: 64px;
          }
        }
      }
    }

    .bannerWrap {
      height: 682px;
      display: flex;
      justify-content: center;

      .banner {
        width: 1208px;
        margin-top: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: -10px;

        .bannerLeft {
          p:nth-of-type(1) {
            width: 241px;
            height: 90px;
            font-size: 64px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2c2c2c;
            line-height: 90px;
          }
          p:nth-of-type(2) {
            width: 567px;
            height: 95px;
            font-size: 68px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2c2c2c;
            line-height: 95px;
          }
          .downLoad {
            margin-top: 63px;
            cursor: pointer;
            img {
              width: 220px;
              height: 65px;
              background: #2254f6;
              border-radius: 8px;
              &:hover {
                box-shadow: 0px 3px 16px 0px rgba(134, 134, 134, 0.72);
                transform: translate(0, -6px);
              }
            }
            img:nth-of-type(2) {
              margin-left: 16px;
            }
          }
        }

        .bannerRight {
          margin-top: 16px;
          .rightPic {
            img {
              width: 612px;
              height: 612px;
            }
          }
        }
      }
    }
  }

  .link {
    cursor: pointer;
    &:hover {
    color: #2254F6;
  }
}
}
</style>
