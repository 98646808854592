<template>
  <div class="outside">
    <div class="main" @scroll="changetopbackground($event)">
      <!-- 导航栏+banner -->
      <div class="topWrap">
        <div class="topArea">
          <Header></Header>

          <div class="bannerWrap">
            <div class="banner">
              <div class="bannerLeft">
                <p>SiLeGer</p>
                <p>想法 · 合作 · 成长</p>
                <div class="downLoad">
                  <img src="../../assets/HomePage/ios@2x.png" alt="" />
                  <img src="../../assets/HomePage/android@2x.png" alt="" />
                </div>
              </div>
              <div class="bannerRight">
                <div class="rightPic">
                  <img src="../../assets/HomePage/img1@2x.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 详细描述 -->
      <div class="detailsWrap">
        <!-- 1 -->
        <div class="details">
          <div class="detailsLeft">
            <img src="../../assets/HomePage/img2@2x.png" alt="" />
          </div>
          <div class="detailsRight">
            <div class="rightDesc">
              <div class="title">
                <img src="../../assets/HomePage/zhuye_icon@2x.png" alt="" />
                <p>主页</p>
              </div>
              <p>掌握最新行业咨询</p>
              <p>
                个性化智能推荐感兴趣的行业动态和活动，浏览最新动态、参与行业活动，让你随时与同行保持同步。
              </p>
            </div>
          </div>
        </div>

        <!-- 2 -->
        <div class="detailsRevert">
          <div class="detailsLeft">
            <div class="LeftDesc">
              <div class="title">
                <img src="../../assets/HomePage/quanzi@2x.png" alt="" />
                <p>圈子</p>
              </div>
              <p>扩大你的专属人脉圈</p>
              <p>
                智能推荐与你志趣相投的人，从相互关注到互为好友，扩大你的朋友圈，放大你的影响力。
              </p>
            </div>
          </div>
          <div class="detailsRight">
            <img src="../../assets/HomePage/img3@2x.png" alt="" />
          </div>
        </div>

        <!-- 3 -->
        <div class="details">
          <div class="detailsLeft">
            <img src="../../assets/HomePage/img4@2x.png" alt="" />
          </div>
          <div class="detailsRight">
            <div class="rightDesc">
              <div class="title">
                <img src="../../assets/HomePage/fabu@2x.png" alt="" />
                <p>发布</p>
              </div>
              <p>分享想法、举办活动</p>
              <p>
                随时分享你的想法、观点和讯息，举办一场会议，发布一个联合课题，组织一场活动。
              </p>
            </div>
          </div>
        </div>

        <!-- 4 -->
        <div class="detailsRevert">
          <div class="detailsLeft">
            <div class="LeftDesc">
              <div class="title">
                <img src="../../assets/HomePage/wode@2x.png" alt="" />
                <p>我的</p>
              </div>
              <p>打造专属名片</p>
              <p>创建专属名片，查看Slager项目，记录你的成长轨迹。</p>
            </div>
          </div>
          <div class="detailsRight">
            <img src="../../assets/HomePage/img5@2x.png" alt="" />
          </div>
        </div>
      </div>

      <!-- 底部 -->
      <div class="bottomWrap">
        <div class="bottom">
          <div class="lineone">
            <span class="link" @click="routerGo('PrivaryPolicy')"
              >隐私政策</span
            >
            <span class="link" @click="routerGo('UserAgreement')"
              >用户协议</span
            >
            <span class="link" @click="routerGo('EventStandard')"
              >活动审核标准</span
            >
          </div>
          <div class="linetwo">
            <span
              >© 2022 - 2023思乐格计算技术（南京）有限公司. All Rights Reserved.
              Designed By Schinper
              <span class="link" @click="newOpen('https://beian.miit.gov.cn/')">
                苏ICP备2022018442号-1</span
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import Header from "../../components/Header";
export default {
  name: "App",
  components: {
    Header,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    function change(type) {
      proxy.$i18n.locale = type;
    }
    const { t } = useI18n();
    return { change };
  },

  methods: {
    newOpen(url) {
      window.open(url);
    },

    routerGo(routeName) {
      const { href } = this.$router.resolve({
        name: routeName,
      });
      window.open(href, '_self');
    },

    changetopbackground(event) {
      let el = event.target;
      if (el.scrollTop) {
        let topbar = document.getElementsByClassName("topAreWrap")[0];
        topbar.setAttribute(
          "style",
          "background-image: none;border-bottom: 1px solid #E9EDF6;z-index:999;background:#fff"
        );
      }
      if (el.scrollTop == 0) {
        let topbar = document.getElementsByClassName("topAreWrap")[0];
        topbar.setAttribute(
          "style",
          "background-image: '../../assets/HomePage/bg@2x.png'"
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.outside {
  height: 100%;

  .main {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    .topWrap {
      width: 100%;
      // height: 752px;
      background-image: url("../../assets/HomePage/bg@2x.png");
      background-repeat: no-repeat;
      background-size: 100% 752px;

      .topArea {
        
        .bannerWrap {
          height: 682px;
          display: flex;
          justify-content: center;

          .banner {
            // width: 1208px;
            margin-top: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: -10px;

            .bannerLeft {
              p:nth-of-type(1) {
                width: 241px;
                height: 90px;
                font-size: 64px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #2c2c2c;
                line-height: 90px;
              }
              p:nth-of-type(2) {
                width: 567px;
                height: 95px;
                font-size: 68px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #2c2c2c;
                line-height: 95px;
              }
              .downLoad {
                margin-top: 63px;
                cursor: pointer;
                img {
                  width: 220px;
                  height: 65px;
                  background: #2254f6;
                  border-radius: 8px;
                  &:hover {
                    box-shadow: 0px 3px 16px 0px rgba(134, 134, 134, 0.72);
                    transform: translate(0, -6px);
                  }
                }
                img:nth-of-type(2) {
                  margin-left: 16px;
                }
              }
            }

            .bannerRight {
              margin-top: 16px;
              .rightPic {
                img {
                  width: 612px;
                  height: 612px;
                  margin-top: 35px;
                }
              }
            }
          }
        }
      }
    }

    .detailsWrap {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: 80px;

      .details {
        width: 1232px;
        height: 668px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        margin: 0 auto;
        .detailsLeft {
          width: 668px;
          height: 668px;
          //  margin-left: -13px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .detailsRight {
          height: 600px;
          margin-right: 13px;
          .rightDesc {
            margin-top: 146px;

            .title {
              width: 114px;
              display: flex;
              justify-content: space-between;
              margin-bottom: 48px;
              img {
                width: 38px;
                height: 38px;
                background: #2254f6;
                border-radius: 3px;
              }
              p {
                font-size: 32px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 37px;
                padding-left: 12px;
              }
            }

            p {
              width: 260px;
              height: 37px;
              font-size: 26px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
              line-height: 37px;
            }
            p:nth-of-type(2) {
              width: 400px;
              height: 81px;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #646e90;
              line-height: 27px;
              padding-top: 20px;
            }
          }
        }
      }

      .detailsRevert {
        width: 1184px;
        height: 668px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        margin: 0 auto;
        padding-top: 72px;
        padding-bottom: 72px;
        .detailsRight {
          width: 626px;
          height: 612px;
          // margin-left: -58px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .detailsLeft {
          //  width: 400px;
          height: 600px;
          .LeftDesc {
            margin-top: 140px;
            .title {
              width: 114px;
              height: 45px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 48px;
              img {
                width: 38px;
                height: 38px;
                background: #2254f6;
                border-radius: 3px;
              }
              p {
                font-size: 32px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 37px;
                padding-left: 12px;
              }
            }
            p {
              width: 260px;
              height: 37px;
              font-size: 26px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
              line-height: 37px;
            }
            p:nth-of-type(2) {
              width: 400px;
              height: 81px;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #646e90;
              line-height: 27px;
              padding-top: 20px;
            }
          }
        }
      }
    }

    .bottomWrap {
      width: 100%;
      height: 102px;
      display: flex;
      justify-content: center;
      border: 1px solid #e9edf6;

      .bottom {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .lineone {
          margin-top: 21px;
          margin-bottom: 14px;
          cursor: pointer;
          span {
            width: 60px;
            height: 21px;
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 21px;
          }
          span:nth-of-type(2),
          span:nth-of-type(3) {
            padding-left: 80px;
          }
        }
        .linetwo {
          margin-bottom: 23px;
          span {
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 21px;
          }

          .link {
            cursor: pointer;
            &:hover {
              color: #2254f6;
            }
          }
        }
      }
    }
  }
  .main::-webkit-scrollbar {
    width: 6px;
    height: 30px;
    border-radius: 3px;
  }

  .main::-webkit-scrollbar-thumb {
    width: 6px;
    height: 30px;
    background: rgba(92, 105, 123, 0.4);
    border-radius: 3px;
  }
}
</style>
