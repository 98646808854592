<template>
  <div id="user">
    <section>
      <div class="title">SiLeGer用户隐私政策</div>
      <div class="date">更新日期：2022年07月02日</div>
      <div class="date">生效日期：2022年07月04日</div>
      <div class="subtitle">引言</div>
      <div class="xiangqing">
        <div>
          SiLeGer（以下简称为“我们”）尊重并保护所有用户的个人信息及隐私安全。同时为给您提供更准确、有个性化的服务和更安全的互联网环境，我们依据《中华人民共和国网络安全法》《中华人民共和国个人信息保护法》《中华人民共和国民法典》《信息安全技术个人信息安全规范》以及其他相关法律法规和技术规范明确了我们收集/使用/对外提供个人信息的原则，进一步阐述了关于您个人信息的相关权利。
        </div>
        <div>
          本政策与您所使用的我们的产品与/或服务息息相关，您在下载、安装、启动、浏览、注册、登录、使用我们的产品与/或服务（以下统称“使用我们的产品与/或服务”）时，我们将按照本政策的约定处理和保护您的个人信息。我们尽量以简明扼要的表述向您解释本政策所涉及的技术词汇，以便于您理解。<strong>
            本政策中与您权益（可能）存在重大关系的条款，我们已使用加粗字体予以区别，请您重点查阅。</strong
          >
        </div>
        <div>
          请在使用/继续使用我们的各项产品与服务前，仔细阅读并充分理解本政策，并在需要时，按照本政策的指引，做出适当的选择。如果您不同意本政策的内容，将可能导致我们的产品与/或服务无法正常运行，或者无法达到我们拟达到的服务效果，您应立即停止访问/使用我们的产品与/或服务。您使用或继续使用我们提供的产品与/或服务的行为，都表示您充分理解和同意本《SiLeGer用户隐私政策》（包括更新版本）的全部内容。
        </div>
      </div>
      <div class="subtitle">关于我们</div>

      <div class="xiangqing">
        <div>SiLeGer网址及APP的经营者为思乐格计算技术（南京）有限公司。</div>
        <div>注册地址为江苏省南京市鼓楼区古平岗4号智梦园B座5楼501-4室。</div>
      </div>

      <div class="subtitle">目录</div>

      <div class="mintitle">
        <a class="link" href="#yi">一、我们如何收集和使用您的个人信息</a>
      </div>
      <div class="mintitle">
        <a class="link" href="#er"
          >二、我们如何共享、转让、公开披露您的个人信息</a
        >
      </div>
      <div class="mintitle">
        <a class="link" href="#san">三、我们如何存储和保护您的个人信息</a>
      </div>
      <div class="mintitle">
        <a class="link" href="#si">四、您如何管理您的个人信息</a>
      </div>
      <div class="mintitle">
        <a class="link" href="#wu">五、您如何注销您的账号</a>
      </div>
      <div class="mintitle">
        <a class="link" href="#liu"
          >六、有关第三方提供产品和/或服务的特别说明</a
        >
      </div>
      <div class="mintitle">
        <a class="link" href="#qi">七、我们如何使用Cookie和其他同类技术</a>
      </div>
      <div class="mintitle">
        <a class="link" href="#ba">八、未成年人保护</a>
      </div>
      <div class="mintitle">
        <a class="link" href="#jiu">九、我们如何更新隐私政策</a>
      </div>
      <div class="mintitle">
        <a class="link" href="#shi">十、如何联系我们</a>
      </div>
      <div class="mintitle">
        <a class="link" href="#shiyi">十一、其他</a>
      </div>

      <div class="mintitle" id="yi">一、我们如何收集和使用您的个人信息</div>
      <div class="mintitle">您理解并同意：</div>
      <div class="xiangqing">
        <div>
          SiLeGer是专注于学术领域交流的社区，用户可以通过图文等形式发布动态，举办活动等，并可以进行互动。我们将在本政策中依次向您说明每项功能可能收集的个人信息范围、收集目的、收集方式，以及拒绝提供个人信息可能导致的结果。请您知悉，您拒绝提供各项功能实现所必要的对应信息时，仍然可以使用SiLeGer App的其他功能。
        </div>

        <div>
          我们会遵循正当、合法、必要的原则，出于本政策所述的以下目的，收集和使用您在使用我们服务过程中主动提供或因使用我们产品和/或服务而产生的个人信息。如果我们要将您的个人信息用于本政策未载明的其它用途，或基于特定目的将已经收集的信息用于其他目的，我们将以合理的方式向您告知，并在使用前再次征得您的同意。
        </div>

        <div class="subxqtitle">1. 账号注册及登录</div>

        <div class="subxq">
          1.1.
          您还可以根据自身需求选择填写或更改性别、头像、及个人介绍来完善您的信息，但如您不提供此类信息，不会影响您使用本产品和相关服务。
        </div>
        <div class="subxq">
          1.2.
          如果您成功注册SiLeGer账号，我们会在SiLeGer应用程序及网站中显示您的用户名、头像、地区、个人介绍信息。
        </div>

        <div class="subxqtitle">2. 向您提供产品和/或服务</div>
        <div class="subxq">2.1. 信息浏览、发布、点赞、分享功能。</div>

        <div class="subxq">
          2.1.1.
          为了向您提供我们最核心的信息展示服务并向您推荐可能感兴趣的动态及相关信息，我们会收集您的性别、浏览记录（您的关注、浏览）和点击操作记录（点击、搜索、点赞、分享、评论、发布记录及有关行为）。若您希望关闭我们基于有关信息向您进行的推荐，您可参照本政策2.6条进行操作。
        </div>

        <div class="subxq">
          2.1.2.
          当您使用发布内容功能时，我们会请求您授权存储（相册、媒体和其他文件）权限。您如果拒绝授权提供，将无法使用相应功能，但不影响您正常使用SiLeGer的其他功能。
        </div>

        <div class="subxq">
          2.1.3.
          当您使用发布、评论、点赞、分享功能时，您发布的文字、照片、评论、点赞、分享记录信息会存储在我们的服务器中，因为存储是实现这一功能所必需的。我们会以加密的方式存储，您也可以随时删除这些信息。
        </div>

        <div class="subxq">
          2.2.安全运行与风控验证。为了保障软件与服务的安全运行，防止您的个人信息被非法获取，更准确地预防欺诈和保护账号安全，我们会收集您的 设备信息【包括：设备型号、设备名称、SIM卡序列号、设备唯一标识符（包括：IMEI、IMSI、AndroidID、IDFA、OAID）】、地理位置、存储（相册、媒体和其他文件）、浏览器类型和设置、语言设置、操作系统和应用程序版本、网络设备硬件地址（MAC地址）、登录IP地址、接入网络的方式、网络质量数据、移动网络信息（包括运营商名称）、产品版本号以及软件使用记录信息，如您不同意我们收集前述信息，可能无法完成风控验证。请您理解，为动态检测您的账户是否存在异常登录，每次您把SiLeGer切换至后台或重新启动程序时，我们可能会再次读取，我们将把读取频次控制在合理范围内。
        </div>

        <div class="subxq">
          2.3.
          搜索功能。当您使用SiLeGer搜索功能时，我们会收集您查询的关键词、阅读记录和访问时间、评论和互动记录。收集此信息可以向您提供您所需要的内容和可能更感兴趣的服务，同时亦可以改进我们的产品和服务。
        </div>

        <div class="subxq">
          2.4.
          当您关注您感兴趣的账号、进行浏览、评论、点赞、分享内容时，我们会收集您关注的账号和前述信息，并向您展示您关注账号发布的内容；同时，您点赞或收藏的内容会向您的粉丝展示。
        </div>

        <div class="subxq">2.5. 身份认证功能及账号找回。</div>

        <div class="subxq">
          2.5.1.
          当您使用身份认证功能申请成为认证用户时，我们可能会收集您的身份信息，包括您的真实姓名、身份证件号码或照片、面部识别信息，用于证实您的个人身份。若您拒绝提供，不会影响SiLeGer其他功能的正常使用。
        </div>

        <div class="subxq">
          2.5.2.
          当您使用账号找回功能时，我们可能会收集您的身份信息，包括您的真实姓名、身份证件号码或照片、面部识别信息，用于证实您的身份，便于您找回SiLeGer账号。若您拒绝提供，不会影响SiLeGer其他功能的正常使用。
        </div>

        <div class="subxq">
          2.6.
          为保证您在使用SiLeGer平台服务时能够与后台服务器保持即时通信、且您能够及时收到个性化内容推送，我们会 i）向您获取及调用您的开机广播权限用于启动SiLeGer或其关联公司移动客户端软件，ii）采用第三方消息推送服务为您生成标识ID用于区分您的设备的唯一性，iii）读取手机状态，用于判断SiLeGer软件处在前台还是后台，以提升推送服务的时效性。
        </div>

        <div class="subxq">
          2.7.
          为向您提供更便捷、更符合您个性化需求的商品信息展示、搜索及推送服务，我们会根据您的 设备信息（包括2.2条所述设备信息及移动应用列表信息） 和您使用SiLeGer客户端时的 浏览使用信息、购买记录， 提取您的偏好特征，并基于特征标签产出间接人群画像，用于向您展示、推送信息和可能的商业广告 (请您理解，单独的设备信息无法识别特定自然人的身份信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化处理）。对于您在使用我们产品或服务的过程中提供的您的联系方式（电话、邮箱及其他联系方式），我们在运营中可能会以短信的方式，为您提供您可能感兴趣的服务，功能或活动及相关商业性信息。我们努力保障您的浏览体验。如果您不想接受我们给您发送的商业广告，您可通过短信提示回复退订或提供的其他方式进行退订或关闭。
        </div>

        <div class="subxq">
          2.8．在您分享或被分享信息、参加活动情形下，我们需要访问您的剪切板，读取其中包含的口令、分享码、链接，以为您实现跳转、分享、活动联动功能或服务。
        </div>

        <div class="subxq">2.9. 设备权限调用汇总</div>
        <div class="subxq">
          我们对SiLeGer在提供服务过程中，会调用您的主要设备权限汇总如下。您可以在设备的设置功能中选择关闭部分或全部权限。在不同设备中，权限显示方式及关闭方式可能有所不同，具体请参考设备及系统开发方说明或指引
        </div>
      </div>

      <table class="sdk" border="1">
        <tr class="first">
          <td>设备权限</td>
          <td>对应业务功能</td>
          <td>功能场景说明</td>
          <td>是否可关闭</td>
        </tr>
        <tr>
          <td><strong> 通知</strong></td>
          <td>系统通知</td>
          <td>APP相关消息推送</td>
          <td>可以，关闭后仅影响对应功能，不影响APP其他功能</td>
        </tr>
        <tr>
          <td><strong>网络权限</strong></td>
          <td>上网功能</td>
          <td>开启上网功能，实时读取与更新</td>
          <td>可以，关闭后APP内容无法实时读取与更新，无法正常使用APP</td>
        </tr>

        <tr>
          <td><strong>相机(摄像头)</strong></td>
          <td>发布内容</td>
          <td>发布内容时进行拍摄</td>
          <td>可以，关闭后仅影响对应功能，不影响APP其他功能</td>
        </tr>

        <tr>
          <td><strong>存储(相册、媒体和其他文件)</strong></td>
          <td>1.内容发布 2.保存内容 3.资料编辑</td>
          <td>
            1.发布内容时读取相册和媒体内容、进行存储 2.日志信息记录、信息缓存
            3.编辑资料选择头像图片时读取相册内容
          </td>
          <td>可以，关闭后仅影响对应功能，不影响APP其他功能</td>
        </tr>

        <tr>
          <td><strong>电话状态(包括设备信息和运行状态)</strong></td>
          <td>展示和其他功能优化</td>
          <td>针对设备信息进行展示</td>
          <td>可以，关闭后仅影响对应功能，不影响APP其他功能</td>
        </tr>
      </table>

      <div class="mintitle" id="er">
        二、我们如何共享、转让、公开披露您的个人信息
      </div>
      <div class="xiangqing">
        <div class="subxqtitle">
          您理解并知悉，为了向您提供更完善、优质的产品和服务，我们将授权商业合作伙伴为您提供部分服务。此种情形下，我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。请您注意，我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们将对信息数据的输出形式、流转、使用进行安全评估与处理，以保护数据安全。同时，我们会对合作伙伴进行严格的监督与管理，一旦发现其存在违规处理个人信息的行为，将立即停止合作并追究其法律责任。
        </div>
      </div>

      <div class="mintitle" id="san">三、我们如何存储和保护您的个人信息</div>
      <div class="xiangqing">
        <div class="subxqtitle">1. 存储：</div>

        <div class="subxq">
          存储地点：我们将从中华人民共和国境内获得的个人信息存放于中华人民共和国境内。以下情形下，我们会确保在履行了法律规定的义务后，向境外实体提供您的个人信息：

          <br />（1）适用的法律有明确规定; <br />（2）获得您的明确授权;

          <br />（3）您通过互联网进行跨境交易等个人主动行为。
          针对以上情形，我们会通过合同等形式确保以不低于本政策规定的程度保护您的个人信息。
          存储时间：我们承诺始终按照法律的规定在合理必要期限内在存储您个人信息。超出上述期限后，我们将删除您的个人信息或对您的个人信息进行匿名化处理。
        </div>
        <div class="subxq">
          如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理。
        </div>
        <div class="subxqtitle">2. 保护：</div>
        <div class="subxq">
          为了保护您的个人信息安全，我们将努力采取各种符合行业标准的安全措施来保护您的个人信息以最大程度降低您的个人信息被毁损、盗用、泄露、非授权访问、使用、披露和更改的风险。我们将积极建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用，确保未收集与我们提供的服务无关的个人信息。
        </div>
        <div class="subxq">
          您的账户均有安全保护功能，请妥善保管您的账户及密码信息。SiLeGer将通过向其它服务器备份、对用户密码进行加密及相关安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。为防止安全事故的发生，我们已按照法律法规的规定，制定了妥善的预警机制和应急预案。如确发生安全事件，我们将及时将相关情况选择以邮件、信函、电话、推送通知及相关方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况，紧密配合政府机关的工作。
        </div>
        <div class="subxq">
          当我们的产品或服务发生停止运营的情形时，我们会及时停止继续收集个人信息的活动。上述变更，我们将以推送通知、公告及相关形式通知你，并在合理的期限内删除你的个人信息或进行匿名化处理（所谓“匿名化处理”，是指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。个人信息经匿名化处理后所得的信息不属于个人信息。）
        </div>
      </div>

      <div class="mintitle" id="si">四、您如何管理您的个人信息</div>

      <div class="xiangqing">
        <div class="subxqtitle">
          我们非常重视并尽全力保障您对自己个人信息的相关权利。
        </div>

        <div class="subxq">1. 自主决定接收信息</div>
        <div class="subxq">
          在关注中会根据您主动选择关注的账号信息进行展示，您可以根据需要自主选择关注或取消关注。
        </div>
        <div class="subxq">2. 个人信息副本的访问、获取、更正和删除相关信息</div>
        <div class="subxq">
          我们通过交互界面的设计，为您提供了可自行访问、获取、更正和删除相关个人信息的渠道，您可通过以下方式进行设置：
        </div>

        <div class="subxq">
          （1）
          通过【我的-编辑资料】访问、获取、更正自己的个人资料，含头像、昵称、性别、个人介绍；
          <br />（2）
          通过【我的-设置-账号与安全】设置、访问、获取、更正账号信息，含手机号、登录密码；
          <br />（3） 通过【我的-设置-黑名单】设置、访问、获取、更正黑名单设置；
          <br />（4）
          通过【我的-粉丝/关注/点赞】访问、获取、更正、删除相关信息。
        </div>
        <div class="subxq">
          除上述外，有下列情形之一的，我们将应当主动删除您的个人信息，您也有权请求我们删除：
        </div>

        <div class="subxq">
          （1）处理目的已实现、无法实现或者为实现处理目的不再必要；
          <br />（2）我们已停止提供产品或者服务，或者保存期限已届满；
          <br />（3）您已明确撤回同意；
          <br />（4）如您有足够的理由认为我们违反法律、行政法规或者违反约定处理您的个人信息；
          <br />（5）法律、行政法规规定的其他情形。
        </div>
      </div>

      <div class="mintitle" id="wu">五、您如何注销您的账号</div>

      <div class="xiangqing">
        <div class="subxqtitle">
          您可以通过【我的-设置-账户与安全-注销账号】，在满足账号注销的条件下选择【同意注销】，来注销您的账号；账号注销后，除法律明确规定必须由我们保留的个人信息外（如您在SiLeGer交易相关的信息），您的个人信息将会从SiLeGer移除。
        </div>
      </div>

      <div class="mintitle" id="liu">
        六、有关第三方提供产品和/或服务的特别说明
      </div>
      <div class="xiangqing">
        <div class="subxq">
          SiLeGer中可能包括第三方产品和/或服务或链接至第三方提供的信息和/或服务，<strong>该第三方产品和/或服务在使用前，您需要跳转到相应的小程序或第三方页面。您使用该第三方服务（包括您向该第三方提供的任何个人信息），须受该第三方的服务条款及隐私政策（而非本政策）约束，您需要仔细阅读其条款并自行决定是否接受。</strong>请您妥善保护自己的个人信息，仅在必要的情况下向他人提供。本政策仅适用于我们所收集、保存、使用、共享、披露信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，<strong
            >第三方使用您信息时的行为由其自行负责。</strong
          >

          <div class="subxqtitle">Android接入第三方SDK目录</div>
          <div class="sdkall">
            <div class="subxqtitle">一、三方SDK名称：Umeng</div>
            <div class="sdk">使用目的：提供内容分享。</div>
            <div class="sdk">功能类型：内容分享。</div>
            <div class="sdk">
              涉及收集个人信息：采集设备标识符（IMEI/MAC/Android
              ID/IDFA/OpenUDID/GUID/SIM 卡 IMSI 信息、网络信息。
            </div>
            <div class="sdk">
              隐私政策链接/官网地址：
              <a href="https://www.umeng.com/page/policy" target="_blank"
                >www.umeng.com</a
              >
            </div>
          </div>

          <div class="sdkall">
            <div class="subxqtitle">二、三方SDK名称：微信开放平台</div>
            <div class="sdk">使用目的：帮助用户分享内容至第三方。</div>
            <div class="sdk">功能类型：内容分享。</div>
            <div class="sdk">涉及收集个人信息：设备识别信息IMSI、IP信息。</div>
            <div class="sdk">
              隐私政策链接/官网地址：
              <a href="https://open.weixin.qq.com/ " target="_blank"
                >open.weixin.qq.com </a
              >
            </div>
          </div>

          <div class="sdkall">
            <div class="subxqtitle">三、三方SDK名称：QQ分享</div>
            <div class="sdk">使用目的：帮助用户分享内容至第三方。</div>
            <div class="sdk">功能类型：内容分享。</div>
            <div class="sdk">涉及收集个人信息：设备识别信息。</div>
            <div class="sdk">
              隐私政策链接/官网地址：
              <a href="https://open.tencent.com/" target="_blank"
                >open.tencent.com/ </a
              >
            </div>
          </div>

          <div class="sdkall">
            <div class="subxqtitle">四、三方SDK名称：极光推送</div>
            <div class="sdk">
              功能：用于实现消息推送（或其他推送）功能，向用户提供个性化、多样性的内容和活动。
            </div>
            <div class="sdk">
              权限：读写外部存储、读取设备标识信息、查看网络连接、查看 WLAN 连接
              数据收集类型：设备信息(IMEI/IDFA/Android
              ID/MAC/OAID/UAID/设备类型/设备型号)、网络状态信息、应用信息(通知开关状态/软件列表)。
            </div>
            <div class="sdk">功能类型：消息推送。</div>
            <div class="sdk">
              备注：用户在后台/静默状态下也会获取用户的MAC信息
            </div>
            <div class="sdk">
              隐私政策链接/官网地址：
              <a href="https://www.jiguang.cn/license/privacy" target="_blank"
                >www.jiguang.cn</a
              >
            </div>
          </div>

          <div class="sdkall">
            <div class="subxqtitle">五、三方SDK名称：小米PUSH</div>
            <div class="sdk">使用目的：向用户推送消息。</div>
            <div class="sdk">功能类型：消息推送。</div>
            <div class="sdk">
              涉及收集个人信息：常用的设备信息、网络连接信息。
            </div>
            <div class="sdk">
              隐私政策链接/官网地址：
              <a
                href="https://dev.mi.com/console/doc/detail?pid=1822"
                target="_blank"
                >dev.mi.com</a
              >
            </div>
          </div>

          <div class="sdkall">
            <div class="subxqtitle">六、三方SDK名称：华为PUSH</div>
            <div class="sdk">使用目的：向用户推送消息。</div>
            <div class="sdk">功能类型：消息推送。</div>
            <div class="sdk">涉及收集个人信息：常用的设备信息。</div>
            <div class="sdk">
              隐私政策链接/官网地址：
              <a
                href="https://consumer.huawei.com/cn/privacy/privacy-policy"
                target="_blank"
                >consumer.huawei.com</a
              >
            </div>
          </div>

          <div class="sdkall">
            <div class="subxqtitle">七、三方SDK名称：OPPO PUSH</div>
            <div class="sdk">使用目的：向用户推送消息。</div>
            <div class="sdk">功能类型：消息推送。</div>
            <div class="sdk">涉及收集个人信息：常用的设备信息。</div>
            <div class="sdk">
              隐私政策链接/官网地址：
              <a
                href="https://open.oppomobile.com/wiki/doc#id=10196"
                target="_blank"
                >open.oppomobile.com</a
              >
            </div>
          </div>

          <div class="sdkall">
            <div class="subxqtitle">八、三方SDK名称：VIVO PUSH</div>
            <div class="sdk">使用目的：向用户推送消息。</div>
            <div class="sdk">功能类型：消息推送。</div>
            <div class="sdk">涉及收集个人信息：常用的设备信息。</div>
            <div class="sdk">
              隐私政策链接/官网地址：
              <a
                href="https://dev.vivo.com.cn/documentCenter/doc/365"
                target="_blank"
                >dev.vivo.com.cn</a
              >
            </div>
          </div>
        </div>
      </div>

      <div class="mintitle" id="qi">七、我们如何使用Cookie和其他同类技术</div>
      <div class="xiangqing">
        <div class="subxqtitle">1. Cookies的使用</div>
        <div class="subxq">
          （1）
          在您接受cookies的情况下，SiLeGer会在您的计算机以及相关移动设备上设定或取用cookies，以便您能登录或使用依赖于cookies的SiLeGer平台服务或功能。SiLeGer使用cookies可为您提供更加周到的个性化服务，包括推广服务。
        </div>
        <div class="subxq">
          （2）
          您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式或在移动设备中设置拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的SiLeGer平台服务或功能。
        </div>
        <div class="subxq">
          （3） 通过SiLeGer所设cookies所取得的有关信息，将适用本政策。
        </div>
      </div>

      <div class="mintitle" id="ba">八、未成年人保护</div>
      <div class="xiangqing">
        <div class="subxqtitle">
          以下条款请未成年用户（特别是未满十四周岁的儿童用户）在监护人的陪同下仔细阅读，并由监护人在充分理解后作出是否接受或拒绝本政策的决定：
        </div>

        <div class="subxq">
          <strong
            >1.
            若用户是未满18周岁的未成年人（特别是未满十四周岁的儿童用户），应在监护人监护、指导并获得监护人同意情况下阅读本协议和使用SiLeGer相关服务。</strong
          >
        </div>
        <div class="subxq">
          <strong
            >2.
            我们重视对未成年人个人信息的保护，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待，请在监护人指导时正确使用SiLeGer相关服务。</strong
          >
        </div>
        <div class="subxq">
          <strong
            >3.
            我们将根据国家相关法律法规及本政策的规定保护未成年人用户信息的保密性及安全性。如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据
            。若您是未成年人的监护人，当您对您所监护的未成年人使用我们的服务或其向我们提供的用户信息有任何疑问时，请您根据本政策第十条提供的联系方式及时与我们联系。</strong
          >
        </div>
      </div>

      <div class="mintitle" id="jiu">九、我们如何更新隐私政策</div>
      <div class="xiangqing">
        <div class="subxq">
          1.
          为了给您提供更好的服务，我们会根据产品的更新情况及法律法规的相关要求更新本政策的条款，这些更新将构成本政策的一部分。未经您明确同意，我们不会削减您依据当前生效的本政策所应享受的权利。
        </div>

        <div class="subxq">
          2.
          如遇本政策更新，我们会通过APP客户端推送通知或其他合理方式通知您，以便您能及时了解本政策的最新版本。
        </div>
      </div>

      <div class="mintitle" id="shi">十、如何联系我们</div>
      <div class="xiangqing">
        <div>
          如您对个人信息保护问题有任何投诉、建议、疑问，或您对本政策有任何疑问，您可以通过以下方式联系我们，我们将尽快审核所涉问题，并在验证您的用户身份后的十五个工作日内予以答复：schinper@163.com。
        </div>
      </div>

      <div class="mintitle" id="shiyi">十一、其他</div>

      <div class="xiangqing">
        <div class="subxq">
          <strong
            >1.
            因本政策以及我们处理您个人信息事宜引起的任何争议，您可诉至有关人民法院。</strong
          >
        </div>
        <div class="subxq">
          <strong
            >2.
            如果您认为我们的个人信息处理行为损害了您的合法权益，您也可向有关政府部门进行反映。</strong
          >
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
#user {
  width: 100%;
  height: 100%;
}
section {
  margin: 0.3125rem 0.9375rem;
  .title {
    text-align: left;
    font-size: 1.25rem;
    font-family: "黑体";
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .subtitle {
    font-size: 0.95rem;
    font-family: "黑体";
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .mintitle {
    font-size: 0.7rem;
    font-family: "黑体";
    font-weight: bold;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .date {
    font-size: 0.7rem;
    margin: 0.1rem 0;
  }
  .xiangqing {
    font-size: 0.7rem;
    color: rgb(15, 15, 15);

    div {
      margin: 0.8rem 0;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
    }

    .subxqtitle {
      margin-left: 0.4rem;
      font-family: "黑体";
      font-weight: bold;
    }

    .subxq {
      margin-left: 0.8rem;
    }

    .sdk {
      margin-top: -0.4rem;
      line-height: 0.9rem;
      margin-left: 0.8rem;
    }
  }
}

.link {
  text-decoration: none;
}
a:link {
  color: rgb(1, 15, 134);
} /* 未被访问的链接 蓝色 */
a:visited {
  color: rgb(1, 15, 134);
} /* 已被访问过的链接 蓝色 */
a:hover {
  color: rgb(1, 15, 134);
} /* 鼠标悬浮在上的链接 蓝色 */
a:active {
  color: rgb(1, 15, 134);
} /* 鼠标点中激活链接 蓝色 */

table {
  border-collapse: collapse;
  font-size: 0.7rem;
  width: 100%;
}

.first {
  font-family: "黑体";
  font-weight: bold;
  text-align: center;
}

table,
th,
td {
  border: 1px solid black;
}

.first {
  line-height: 1.5rem;
  max-width: 3rem;
  text-align: center;
}

td {
  line-height: 1.5rem;
  max-width: 3rem;
}
td a {
  word-break: break-all;
  text-decoration: none;
}
</style>