<template>
  <div>
    <main>
      <div class="about">

        <div class="contact">
          <div class="header">
            <div class="title">联系我们</div>
            <div class="split"></div>
          </div>
          <div class="contact_us">
            <div>
              如您对思乐格的产品和技术感兴趣或有任何疑问，请通过一下方式与我们联系： </div>
            <div>·地址：南京市鼓楼区古平岗4号智梦园B座5楼</div>
            <div>·电话：025-86792862</div>
            <!-- <div>·邮箱：</div> -->
            <!-- <div>·公众号</div> -->
          </div>
          <!-- <div class="img">
            <img src="../assets/Home/gongzhonghao@2x.png" alt="" />
          </div> -->
        </div>
      </div>
    </main>
  </div>
</template>

<script>

export default {
};
</script>

<style lang="scss" scoped>
main {
  // background-image: url("../assets/about_bg@2x.png");

  .about {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .header {
      margin-top: 80px;
      .title {
        height: 42px;
        font-size: 30px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 42px;
      }
      .split {
        width: 32px;
        height: 6px;
        background: #4568ee;
        margin: 18px 0 32px 0;
      }
    }

    .contact {
      margin-bottom: 80px;
      .contact_us {
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 48px;
      }
      .img {
        width: 234px;
        height: 234px;
        img {
          width: 234px;
          height: 234px;
        }
      }
    }
  }
}
</style>