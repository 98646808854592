<template>
  <div id="user">
    <section>
      <div class="title">SiLeGer活动审核标准（V1.0.0）</div>
      <div class="date">更新日期：2022年07月05日</div>
      <div class="date">生效日期：2022年07月06日</div>
      <div class="subtitle">引言</div>
      <div class="xiangqing">
        <div>
          开放、安全、诚信是SiLeGer平台的运营准则，为保障活动主办方及参与者的合法权益，维护和谐的网络商业环境，特拟定如下活动发布及审核标准，请大家共同遵守！
        </div>
        <div>
          SiLeGer严厉打击违法、违规和虚假活动；鼓励优质、健康的活动！针对优秀活动，SiLeGer将给予流量扶持和强力推荐。
        </div>
        <div>
          如您对审核结果或审核规则有疑问，请致电客服专线：025-86792862进行申诉！欢迎您提出宝贵建议！
        </div>
      </div>

      <div class="subtitle">目录</div>

      <div class="mintitle">
        <a class="link" href="#yi">一、活动驳回</a>
      </div>
      <div class="mintitle">
        <a class="link" href="#er">二、活动屏蔽（不推荐）</a>
      </div>
      <div class="mintitle">
        <a class="link" href="#san">三、特殊类型活动</a>
      </div>
      <div class="mintitle">
        <a class="link" href="#si">四、违规处罚说明</a>
      </div>
      <div class="mintitle">
        <a class="link" href="#wu">五、优质活动奖励</a>
      </div>

      <div class="mintitle" id="yi">一、活动驳回</div>
      <div class="mintitle">出现以下情形的活动将会被驳回，对于严重违规的账号将进行封号处理：</div>
      <div class="xiangqing">
        <div class="subxqtitle">1. 违反法律法规的活动：</div>

        <div class="subxq">
          1.1.
          违反国家法律法规，危害国家安全，涉及国家领导人姓名、肖像等损害国家利益，活动内容含政治、色情、赌博、毒品、宣扬邪教、封建迷信、恶俗用语等
        </div>
        <div class="subxq">
          1.2.
          违反《广告法》，出现绝对化文字或用语的，包括但不限于：最大、第一、顶级、世界领先等
        </div>
        <div class="subxq">
          1.3.
         侵犯他人知识产权，如商标侵权、活动海报或字体侵权等
        </div>
        <div class="subxq">
          1.4.
          活动内容夸大其词，存在煽动型文案，违背科学常理、与事实不符，疑似虚假宣传的
        </div>
        <div class="subxq">
          1.5.
          涉嫌传销、炒币、ICO发行、集资、捐款、炒股、重金属期货交易、加盟分销等内容
        </div>
        <div class="subxq">
          1.6.
          以活动或讲座名义，销售或推荐健康理疗、保健品的
        </div>

        <div class="subxqtitle">2. 疑似虚假活动：</div>
        <div class="subxq">2.1. 主办方身份存疑，假冒他人或虚假身份发布活动、欺骗用户的</div>
        <div class="subxq">2.2. 抄袭他人活动标题及内容，但未获取对方相关授权的</div>

        <div class="subxqtitle">3. 存在SiLeGer以外的报名方式：</div>
        <div class="subxq">3.1. 活动海报、活动详情中出现非SiLeGer平台报名入口信息的，包含其他的网站链接、二维码、手机号、QQ群等</div>

        <div class="subxqtitle">4. 活动时间、地址不明确的：</div>
        <div class="subxq">4.1. 线下活动，但活动地址不明确或虚假地址的（若场地暂未确定或需保密的，可注明何时以何种方式的通知参与者）</div>
        <div class="subxq">4.2. 活动时间、活动地址与活动详情中描述不一致</div>
        <div class="subxq">4.3. 活动内容为线下活动但发布为线上活动，不标明活动举办地址的</div>
        <div class="subxq">4.4. 线下活动举办时间超过30天的（艺术展览类活动的举办时间不受限制）</div>

        <div class="subxqtitle">5. 诱导分享的：</div>
        <div class="subxq">5.1. 活动标题及活动详情中存在诱导用户集赞、分享转发获免费票券或名额的</div>
      </div>

      <div class="mintitle" id="er">
        二、活动屏蔽（不推荐）
      </div>
      <div class="mintitle">存在以下行为的主办方，其发布的活动将会被屏蔽（不推荐）</div>
      <div class="xiangqing">
        <div class="subxqtitle">1. 无故多次修改活动时间、地址，且未通知参与者</div>
        <div class="subxqtitle">2. 曾被举报活动未按约定时间、地点举办的主办方，所发布活动不予推荐；如被举报超过3次，将会被封号处理</div>
        <div class="subxqtitle">3. 曾被投诉活动无故取消不通知用户的主办方，所发布活动不予推荐</div>
        <div class="subxqtitle">4. 无法联系到活动主办方，多次拒接SiLeGer客服电话，无法进行主办方身份核实、不积极配合进行参与者投诉或纠纷处理的，不予推荐</div>
        <div class="subxqtitle">5. 活动详情描述过于简单，未明确说明活动流程和具体参与方式的；</div>
        <div class="subxqtitle">6. 活动海报不美观，活动内容排版混乱的</div>
      </div>

      <div class="mintitle" id="san">三、特殊类型活动</div>
      <div class="mintitle">以下类型的活动，由于被投诉或产生纠纷的比例较高，若出现以下情形将不被推荐。       若您希望活动被推荐，可联络SiLeGer平台提供相关资质进行实名认证，或签署活动推广合作协议，
        声明纠纷处理方式或风险担保：</div>
      <div class="xiangqing">
        <div class="subxqtitle">1. 在线课程类</div>
        <div class="subxq">1.1. “SiLeGer代销类”课程，但未在活动详情中说明上课方式的，不予推荐。</div>
        <div class="subxq">1.2. 发布为“音视频类”课程，未提供音视频素材给SiLeGer的，暂不审核通过</div>
        <div class="subxq">1.3. 发布为“在线直播类”课程，未明确设置上课时间和上课方式的，不予推荐</div>

        <div class="subxqtitle">2. 相亲交友类</div>
        <div class="subxq">2.1. 活动现场另行收费、欺骗参与者的相亲交友类活动，不予推荐</div>
        <div class="subxq">2.2. 活动详情中，未注明报名后的联系人及联系方式的，不予推荐</div>
        <div class="subxq">2.3. 主办方未实名认证，无法辩知真伪或相关资质不明确的，不予推荐</div>

        <div class="subxqtitle">3. 户外/旅游类</div>
        <div class="subxq">3.1. 旅游或户外活动，未设置明确的集合时间、地点，未告知报名后的联络人及联络方式的，不予推荐</div>
        <div class="subxq">3.2. 在活动详情中，未说明旅游路线及流程的，不予推荐</div>
        <div class="subxq">3.3. 活动详情中，“有额外消费但未进行说明的”，不予推荐</div>
        <div class="subxq">3.4. 曾经被投诉过，活动无故取消不通知用户的主办方，不予推荐</div>
        <div class="subxq">3.5. 主办方未实名认证，无法辩知真伪或相关资质不明确的，不予推荐</div>

        <div class="subxqtitle">4. 招生/招募/体验/教育培训类（海外留学、手工、亲子体验等）</div>
        <div class="subxq">4.1. 免费试听、试玩、免费体验以及产品宣讲类活动，不予推荐</div>
        <div class="subxq">4.2. 免费的形象管理、语言/兴趣/技能培训课程，不予推荐</div>

        <div class="subxqtitle">5. 游学考察类</div>
        <div class="subxq">5.1. 主办方未实名认证，无法辩知真伪或者相关资质不明确的，不予推荐</div>
        <div class="subxq">5.2. 免费的出国考察类活动，不予推荐</div>

        <div class="subxqtitle">6. 心理咨询类</div>
        <div class="subxq">6.1. 主办方未实名认证，无法辩知真伪的活动，未声明具有相关资质的，不予推荐</div>
        <div class="subxq">6.2. 免费的NLP心理学课程活动，不予推荐</div>
        <div class="subxq">6.3. 高票价、时长超过3天，易产生纠纷的NLP课程，不予推荐</div>

        <div class="subxqtitle">7. 展会类</div>
        <div class="subxq">7.1. 主办方未实名认证，无法辩知真伪的展会类活动，不予推荐</div>
        <div class="subxq">7.2. 展会类活动，举办时间距离当前时间超过6个月，举办存在风险的，不予推荐</div>

        <div class="subxqtitle">8. 区块链类</div>
        <div class="subxq">8.1. 主办方未进行企业实名认证，具有国家政策风险的活动，不予推荐</div>
        <div class="subxq">8.2. 仅支持区块链技术探讨类活动，若以区块链为噱头口号实质涉及虚拟货币 ico token 相关活动 不予发布</div>

        <div class="subxqtitle">9. 线上活动类</div>
        <div class="subxq">9.1. 线上征稿、竞赛、评选等类似的活动，不予推荐，主办方可自行推广</div>
        <div class="subxq">9.2. 线上招募类活动，在活动结束前无线下活动的，不予推荐</div>

        <div class="subxqtitle">10. 产品推销/推荐类</div>
        <div class="subxq">10.1. 无实质活动内容，纯产品推荐、广告类的活动，不予推荐</div>

      </div>

      <div class="mintitle" id="si">四、违规处罚说明</div>

      <div class="xiangqing">
        <div class="subxqtitle">
          1. 在使用SiLeGer平台时，请您理解并遵守SiLeGer平台管理规范和审核规则，保证您所发布活动的真实性、合法性，并承诺不侵犯第三方的知识产权及合法权益，
          不存在违反法律、行政法规的强制性、禁止性规定及公序良俗的情形。若因违反前述约定产生的任何纠纷，将由您自行全部承担；若SiLeGer平台因此遭受损失的，则由您承担全部赔偿。
        </div>

        <div class="subxqtitle">
          2. 违规活动处罚说明
        </div>
        <div class="subxq">2.1. 无故取消活动，且未提前通知已报名参与者的，取消其发布活动资格；视情节严重性，账号封禁时间为 3个月~永久 不等</div>
        <div class="subxq">2.2. 发布虚假活动，假冒他人或虚假身份发布活动、欺骗用户的，永久列入黑名单</div>
        <div class="subxq">2.3. 被投诉未按约定时间、地点举办活动的，取消其发布活动资格，永久列入黑名单</div>
        <div class="subxq">2.4. 同城市，同一账号或同公司多个账号发布大量相同重复活动的，所有账号发布的活动，永久不推荐</div>
      </div>

      <div class="mintitle" id="wu">五、优质活动奖励</div>
      <div class="mintitle">符合以下标准的活动为优质活动，可能会被精选为强力推荐或专题推荐，对于特别优质的活动，可能会获得全平台push消息推荐：</div>
      <div class="xiangqing">
        <div class="subxqtitle">1. 符合SiLeGer平台审核标准，未出现违规行为的主办方</div>
        <div class="subxqtitle">2. 活动海报精美、活动详情排版清晰、美观的活动</div>
        <div class="subxqtitle">3. 活动主题新颖、活动内容符合行业或当前社会趋势、积极健康的</div>
        <div class="subxqtitle">4. 活动流程清晰，活动亮点和目标人群明确，嘉宾和议程介绍详细的</div>
        <div class="subxqtitle">5. 已实名认证、主办方身份和相关资质明确，被投诉风险低的活动</div>
        <div class="subxqtitle">6. 符合以上标准的，将优先推荐：</div>
        <div class="subxq">6.1. 活动浏览量、报名高，深受广大用户喜欢的活动</div>
        <div class="subxq">6.2. 活动服务好，历史评价多、且积极正面的活动</div>
        <div class="subxq">6.3. 将SiLeGer平台作为独家或官方报名渠道，且积极宣传SiLeGer报名入口的活动</div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
#user {
  width: 100%;
  height: 100%;
}
section {
  margin: 0.3125rem 0.9375rem;
  .title {
    text-align: left;
    font-size: 1.25rem;
    font-family: "黑体";
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .subtitle {
    font-size: 0.95rem;
    font-family: "黑体";
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .mintitle {
    font-size: 0.7rem;
    font-family: "黑体";
    font-weight: bold;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .date {
    font-size: 0.7rem;
    margin: 0.1rem 0;
  }
  .xiangqing {
    font-size: 0.7rem;
    color: rgb(15, 15, 15);

    div {
      margin: 0.8rem 0;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
    }

    .subxqtitle {
      margin-left: 0.4rem;
      font-family: "黑体";
      font-weight: bold;
    }

    .subxq {
      margin-left: 0.8rem;
    }

    .sdk {
      margin-top: -0.4rem;
      line-height: 0.9rem;
      margin-left: 0.8rem;
    }
  }
}

.link {
  text-decoration: none;
}
a:link {
  color: rgb(1, 15, 134);
} /* 未被访问的链接 蓝色 */
a:visited {
  color: rgb(1, 15, 134);
} /* 已被访问过的链接 蓝色 */
a:hover {
  color: rgb(1, 15, 134);
} /* 鼠标悬浮在上的链接 蓝色 */
a:active {
  color: rgb(1, 15, 134);
} /* 鼠标点中激活链接 蓝色 */

table {
  border-collapse: collapse;
  font-size: 0.7rem;
  width: 100%;
}

.first {
  font-family: "黑体";
  font-weight: bold;
  text-align: center;
}

table,
th,
td {
  border: 1px solid black;
}

.first {
  line-height: 1.5rem;
  max-width: 3rem;
  text-align: center;
}

td {
  line-height: 1.5rem;
  max-width: 3rem;
}
td a {
  word-break: break-all;
  text-decoration: none;
}
</style>