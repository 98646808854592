import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import HomePage from '../views/Home/HomePage'
import EventStandard from  '../views/EventStandard.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import UserAgreement from '../views/UserAgreement.vue'
import About from '../views/About.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/PrivaryPolicy',
    name: 'PrivaryPolicy',
    component: () =>
    import(
      /* webpackChunkName: "PrivaryPolicy" */ "../views/JumpPages/PrivaryPolicy.vue"
    ),
  },
  {
    path: '/UserAgreement',
    name: 'UserAgreement',
    component: () =>
    import(
      /* webpackChunkName: "UserAgreement" */ "../views/JumpPages/UserAgreement.vue"
    ),
  },
  {
    path: '/EventStandard',
    name: 'EventStandard',
    component: () =>
    import(
      /* webpackChunkName: "EventStandard" */ "../views/JumpPages/EventStandard.vue"
    ),
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: () =>
    import(
      /* webpackChunkName: "AboutUs" */ "../views/JumpPages/AboutUs.vue"
    ),
  },


  {
    path: '/user_agreement',
    name: 'user_agreement',
    component: UserAgreement
  },
  {
    path: '/privacy_policy',
    name: 'privacy_policy',
    component: PrivacyPolicy
  },
  {
    path: '/event_standard',
    name: 'event_standard',
    component: EventStandard
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
